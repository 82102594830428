import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReparationBanner from "../components/reparationBanner";

class ReparationWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<ReparationBanner />}>
                <SEO title="Justice Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-box"><img className="img--layout" src="https://demo.artureanec.com/html/helpo/img/blog_bg-2.png" alt="img" style={{top: '34%'}} />
                                    <div className="img-box__img">
                                        <img alt="education" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/mauroyange-nappy.jpg" className="img--bg m-b-10" style={{height: '42%', marginTop: 125}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <h2 className="text-medium"><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/mmere-dane-small.png" width="40" /> Reparation</h2>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li>Reparations for African Nova Scotian and African Canadians in the context of the overall global movement.</li>
                                    <li>Recognize the wrongs of the past (enslavement, segregation and racial exploitation) including present systemic, institutional, economic, psychological and environmental racism and its negative effects on African Nova Scotian communities</li>
                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li>Access, implement and monitor the case for reparations from several perspectives including economic, social, political, health and culture</li>
                                    <li>Hire a coordinator who will bring together local, national and international educators, activists, scholars, service providers and community members to develop a position that examines the connection between slavery and oppression and systemic inequalities and challenges African NS face in the areas of education, employment, environment, health and mental health, policing and justice. </li>
                                    <li>Establishment of the Canadian Reparations Commission</li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-gavel"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        );
    }
}

export default ReparationWorkingGroup